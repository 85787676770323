import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MainLogo from "assets/svg/MainLogo";
import "pages/Order/OrderPage.scss";
import StepProgress from "components/OrderPage/StepProgress/StepProgress";
import useWindowDimensions from "utils/WindowWidth";

import {useDispatch, useSelector} from "react-redux";
import {setAutopayCredit, setEmailStep, setInternet, setStep,} from "store/reducers/order/orderSlice";

import StepThree from "components/OrderPage/StepThree";
import StepOne from "components/OrderPage/StepOne/StepOne";
import StepTwo from "components/OrderPage/StepTwo/StepTwo";
import StepFour from "components/OrderPage/StepFour/StepFour";
import StepFive from "components/OrderPage/StepFive/StepFive";

import TotalOrder from "components/OrderPage/TotalOrder/TotalOrder";
import Caret from "assets/Icons/Caret";
import {useLazyGetInternetPackagesQuery, useVerificationCodeMutation,} from "api/beanfield/OrderPage";
import {toggleModal} from "store/reducers/modal/modalSlice";
import Button from "components/reusable/Button/Button";
import {useGetOrderPageData} from "services/api/useGetOrderPageData";
import parseQueryStringToDictionary from "utils/urlParams";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import {useResetStateAndSetAddress} from "services/hooks/useResetStateAndSetAddress";
import {useLazyGetSearchIdQuery} from "api/beanfield/Search";
import {minTwoDigits} from "utils/twoDigitsAtTheEnd";
import {LegalAccordion} from "components/SupportAccordion/SupportAccordion";
import {ControlledAccordion, useAccordionProvider} from "@szhsin/react-accordion";
import {singlePromoPackageID} from "utils/xgsponConstants";
import {getAutopayCredit} from "utils/autopay";


const LegalTagline = ({ data }) => {
      const accordionValue = useAccordionProvider({
        allowMultiple: false,
        transition: true,
        transitionTimeout: 200,
      });

      const { toggle } = accordionValue;
      /* LEGAL TAGLINE */
      if(data?.offer?.show_offer_details) {
        return (
          <div className="resi-container" style={{maxWidth: "30rem"}}>
             <div>
                <div className="internet-legal-tagline-container">
                   <ControlledAccordion providerValue={accordionValue}>
                      <LegalAccordion title={data?.offer?.offer_details_title} body={data?.offer?.offer_details_body} toggle={toggle} />
                   </ControlledAccordion>
                </div>
             </div>
          </div>

        )
      }
      return null
  }

const OrderPage = () => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const { order, userInfo } = useSelector((state) => state.orderSlice);
  const { resetStateAndSetAddress } = useResetStateAndSetAddress();
  const [getSearch, { data: addressData }] = useLazyGetSearchIdQuery();

  const location = useLocation().pathname.split("/")[2];
  const [open, setOpen] = useState(false);

  //INTERNET PACKAGES
  const [getInternetPackages, { data: internetPackages }] =
    useLazyGetInternetPackagesQuery({
      address_id: userInfo?.address_id,
    });

  const findBFPackageId = () => {
    return internetPackages?.packages.find((pkg) => {
      return pkg.id === singlePromoPackageID
    })
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //PRE-SELECTED INTERNET PACKAGE FOR BC AND ONTARIO
  useEffect(() => {
    if (!order.internet.internetPackageSelected) {
      if (location === "bc") {
        if (internetPackages?.packages) {
          dispatch(
            setInternet({
              selected: internetPackages?.packages[0]?.id,
              title:
                internetPackages?.packages[0]?.title.split("-")[0] ||
                internetPackages?.packages[0]?.title,
            })
          );
        }
      } else {
        if (internetPackages?.packages) {
          dispatch(
            setInternet({
              selected: (cmsData?.step_1_use_promo && findBFPackageId()) ? singlePromoPackageID : internetPackages?.packages[0]?.id,
              title: internetPackages?.packages[0]?.title.split("-")[0],
            })
          );
        }
      }
    } else {
      if (internetPackages?.packages) {
        const selectedPackage = internetPackages.packages.find((pkg) => {
          return pkg.id === order.internet.internetPackageSelected;
        });
        if(selectedPackage) {
          dispatch(
            setInternet({
              selected: selectedPackage.id,
              title: selectedPackage.title.split("-")[0],
              price: Number(selectedPackage.price.toFixed(2)) - getAutopayCredit(selectedPackage),
              internetPackageSelected: selectedPackage.id
            })
          );
          dispatch(setAutopayCredit(getAutopayCredit(selectedPackage)));
        } else {
          dispatch(
            setInternet({
              selected: internetPackages?.packages[0]?.id,
              title: internetPackages?.packages[0]?.title.split("-")[0],
              internetPackageSelected: "",
            })
          );
          dispatch(setAutopayCredit(getAutopayCredit(internetPackages?.packages[0])));
        }
      }
    }
  }, [internetPackages?.packages, order]);

  const { localization, region } = useSelector(
    (state) => state.localizationSlice
  );

  //CMS INFO
  const { data: cmsData } = useGetOrderPageData(useLocation);
  const navigate = useNavigate();

  //TEMPORARY PARAM FROM URL
  const paramFromUrl = parseQueryStringToDictionary(useLocation().search);
  const [verifyCode, { data: codeData, error: codeError }] =
    useVerificationCodeMutation();

  //THIS FOR STEP 3 EMAIL VERIFICATION (when we click link from email)
  useEffect(() => {
    if (paramFromUrl?.vt) {
      if (!!(order.installationDate && userInfo.email && userInfo.address)) {
        verifyCode({ email: userInfo?.email, code: paramFromUrl?.vt });
        dispatch(setStep(2));
      } else {
        navigate(`/${localization}/${region}`);
      }
    } else if (paramFromUrl.address_id) {
      getSearch({ address_id: paramFromUrl.address_id });
      getInternetPackages({ address_id: paramFromUrl.address_id });
      if (addressData) {
        addressData.map((item) => {
          return resetStateAndSetAddress(item);
        });
      }
    } else {
      if (!userInfo?.address_id) {
        navigate(`/${localization}/${region}`);
      } else {
        getInternetPackages({ address_id: userInfo?.address_id });
      }
    }
  }, [addressData]);
  useEffect(() => {
    if (codeData?.success && codeData?.verified) {
      dispatch(setEmailStep(3));
    }
  }, [codeData]);

  //EXIT MODAL
  const modalContent = () => {
    return (
      <div className="address_warning_container" tabIndex="0">
        <h2>{cmsData?.modal_warning_exit_header}</h2>
        <p style={{ margin: "20px 0px", maxWidth: "70%", textAlign: "center" }}>
          {cmsData?.modal_warning_exit_body}
        </p>
        <div className="address_warning_btn_wrapper">
          <Button
            click={() => dispatch(toggleModal())}
            btnStyle={"beanfield-button--hollow green Wbold"}
          >
            {cmsData?.modal_warning_exit_btn_cancel}
          </Button>
          <Button
            click={() => {
              dispatch(toggleModal());
              navigate(`/${localization}/ca`);
            }}
            btnStyle="beanfield-button Wbold"
          >
            {cmsData?.modal_warning_exit_btn_confirm}
          </Button>
        </div>
      </div>
    );
  };

  const pageSteps = () => {
    switch (order?.currentStep) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      case 3:
        return <StepFour />;
      case 4:
        return <StepFive />;
    }
  };

  return (
    <>
      <div className="orderPage_container">
        <div className="orderPage_content_wrapper">
          <MainLogo
            className="orderPage_logo"
            onClick={() => dispatch(toggleModal({ children: modalContent() }))}
            tabIndex="0"
          />
          <h1 tabIndex="0">{cmsData?.page_header}</h1>
          {/* PROGRESS BAR */}
          {width > 750 ? <StepProgress /> : null}

          {/* MAIN CONTENT */}
          <div className="orderPage_main_container" >{pageSteps()}</div>
        </div>
        {/* FOOTER */}
        <div className="orderPage_contact_container">
          <ContactInfo
            title={cmsData?.contact_us_page?.title}
            description={cmsData?.contact_us_page?.description}
            badges={cmsData?.contact_us_page?.badges}
          />
        </div>
        {order?.currentStep === 0?
            <div style={{backgroundColor: "rgba(237, 237, 237, 0.15)"}}><LegalTagline data={ cmsData } /></div>
            : null
        }
      </div>

      {/* STICKY FOOTER FOR ORDER TOTAL */}
      {width < 768 ? (
        <div
          className="orderPage_sticky_footer_container"
          onPointerEnter={() => setOpen(!open)}
        >
          <div className="sticky_footer_caret">
            <Caret
              onPointerEnter={() => setOpen(!open)}
              color="black"
              turn={open ? "down" : "up"}
            />
          </div>
          {!open ? (
            <div className="sticky_footer_total">
              <span className="Wbold">{cmsData?.total_txt}</span>
              <span className="Wbold">
                ${minTwoDigits(order?.total || 0)}
                {cmsData?.total_sfx}
              </span>
            </div>
          ) : null}
          {open ? (
            <div
              style={{
                overflowY: "auto",
              }}
            >
              <TotalOrder />
            </div>
          ) : null}
        </div>
      ) : null}

    </>
  );
};

export default OrderPage;
