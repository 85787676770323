import React from "react";
import "components/reusable/HomeInternetBox/HomeInternetBox.scss";
import { convertHtmlToReact, } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { convertNodeToReactElement } from '@hedgedoc/html-to-react/dist/convertNodeToReactElement';

import Button from "components/reusable/Button/Button";
import ToolTip from "components/reusable/Tooltip/Tooltip";
import CheckMarkOrder from "assets/Icons/CheckMarkOrder";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";
import ToolTipMobile from "components/reusable/Tooltip/TooltipMobile";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { googleEventMap, internetPackageIds } from "utils/xgsponConstants";
import {WagtailImage} from "components/Wagtail/WagtailImage";

const HomeInternetBox = ({ data, index }) => {
    const dispatch = useDispatch();
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const handleCheckAvailability = () => {
      const internetPackageSelected = internetPackageIds[index];
      dispatch(toggleModal({ children: <AddressModal internetSelected={internetPackageSelected} /> }));
      sendGoogleTagEvent({eventName: googleEventMap[internetPackageSelected]});
    };

    const transform = (node, index) => {
      if (node.type === "text") {
          if( node.data.includes("{tooltip}")) {
             node.data =  <>
              <span><CheckMarkOrder fill="#325A32"/></span>
              <span className="feature-point">
                {node.data.replace("{tooltip}", "")}
                {width < 768 ? (
                  <ToolTipMobile
                    contentStyles={{ top: -5 }}
                    helperStyles={{ top: 0, left: 15 }}
                    iconStyles={{ marginBottom: "0", marginTop: "0" }}
                  >
                    <p>{data.tooltip_text}</p>
                  </ToolTipMobile>
                ) : (
                  <ToolTip
                    contentStyles={{ top: -5 }}
                    helperStyles={{ top: 0, left: 15 }}
                    iconStyles={{ marginBottom: "0", marginTop: "0" }}
                  >
                    <p>{data.tooltip_text}</p>
                  </ToolTip>
                )}

              </span>
          </>;
          }
          else {
              node.data = <>
                  <span><CheckMarkOrder fill="#325A32"/></span>
                  <span className="feature-point">{node.data}</span>
              </>;
          }
          return convertNodeToReactElement(node, index);
      }
    };

  return (
    <div className={data.use_promo ? "home-internet-box-item black-friday-promo" : "home-internet-box-item"} key={index}>
      <div className="info-container">
        <div className="title-box">
          <span className="title Wbold">{data?.title}</span>
          <br />
          <span className="speed Wbold">{data?.speed}</span>
        </div>
        <div className="blurb">{convertHtmlToReact(data?.description)}</div>
        <div className="features">
          { data?.internet_features.map( feature => {
            return  convertHtmlToReact(feature.value, {transform: transform});
          })}
        </div>
      </div>
      <div className="price-container">
        <div className="price-values">
          <div className="regular-price">
            <p className={data.use_promo ? "number-dollar Wbold black-friday-promo" : "number-dollar Wbold"}>$</p>
            <p
              className={data.use_promo ? "number-value Wbold black-friday-promo" : "number-value Wbold"}>{data?.price}</p>
            {data.use_promo ? (
              <>
                <p className="number-dollar Wbold">$</p>
                <p className="number-value Wbold">{data?.promo_price}</p>
              </>
            ) : null}
            <div className="extra-info">
              <p className="number-tax">{data.plan_tax}</p>
              <p className="number-text">/mo.</p>
            </div>
          </div>
          {data?.autopay_credit_amount ?
            <div className="autopay-price">
              <div className="extra-info">
                <p className="number-tax"></p>
                <p className="promo-number-text"><s>${data?.autopay_credit_amount}/mo.</s></p>
              </div>
            </div>
          : null}
        </div>
        {data?.autopay_credit_text ?
          <div className="credit-text">
            {data?.autopay_credit_text}
          </div>
          : null}
        <Button
          className="availability-btn beanfield-button"
          click={() => handleCheckAvailability()}
        >
          {data?.button_text}
        </Button>
      </div>
      {data.use_promo ? (
        <>
          <div className="black-friday-image-offset">
            <WagtailImage image={data.promo_image}/>
          </div>
          <div className="black-friday-image-offset-mobile">
            <WagtailImage image={data.promo_mobile_image}/>
            <div className="offset-border"></div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default HomeInternetBox;
